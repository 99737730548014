.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner-rotate 1s infinite linear;
  }
  
  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  